<template>
  <div ref="navBox" class="gt-nav">
    <!-- <div
        class="gt-nav__line"
        :style="`transform: translateX(${lineLeft}px);width: ${lineWidth}px;`"
      ></div> -->
    <div v-if="showMore" class="more-btn-box" style="margin-right: 32px" @mousemove="backFn">
      <img src="@/assets/ua-icon24/icon-arrow-left-small-white.svg" alt="icon-arrow-right-small" />
      <span class="nav-more">返回</span>
    </div>

    <div
      v-for="(item, index) in navData"
      ref="$nav_item"
      :key="`${index}_${item.navigationCode}`"
      :class="{
        'gt-nav__item': true,
        'gt-nav__item__children': item.children && item.children.length,
        'gt-nav__item-fill': item.level === 3,
        active: item.navigationCode === activeNavCode,
        'has-image': item.thirdHasImage
      }"
      @mouseenter="mouseEnter($event, item)"
      @click="handleClick"
    >
      <div class="gt-nav__item-content">
        <gt-link
          v-hover-track="{
            eventName: 'selectType',
            data: {
              click_type: '悬浮',
              navigation_primary_category: item.navigationTitle
            }
          }"
          v-click-track="{
            eventName: 'selectType',
            data: {
              click_type: '点击',
              navigation_primary_category: item.navigationTitle
            },
            extraCacheData: {
              source_navigation: `${item.navigationTitle}`
            },
            beforeTrack: () => {
              $sensors.registerPage({
                source_first_nav: item.navigationTitle,
                source_navigation: item.navigationTitle
              });
            },
            registerPage: {
              source_first_nav: item.navigationTitle,
              source_navigation: item.navigationTitle
            }
          }"
          :class="{
            'gt-nav__item-label': true,
            'is-active':
              active(item) ||
              item.navigationCode === clickNav.navigationCode ||
              item.navigationUrl === $route.path ||
              item.navigationUrl === $route.fullPath
          }"
          :data-id="item.navigationCode"
          :to="item.navigationUrl || ''"
          @click="clickFn(index, item)"
        >
          <slot name="title" :data="item">
            <i v-if="isMobile" class="gt-nav__icon iconfont icon-a-24-1px-right-s"></i>
            <span style="border: none">{{ item.navigationTitle }}</span>
          </slot>
        </gt-link>
        <!-- -->
        <div
          v-if="item.children && item.children.length && item.navigationTitle !== '探索'"
          ref="subNav"
          :class="{
            'gt-nav__sub': true,
            'has-image': item.thirdHasImage,
            'gt-nav__sub-fill': item.level === 3,
            'sub-hidden': subHidden
          }"
        >
          <div class="gt-nav__sub-wrap">
            <!-- <slot :data="item" ref="$subnav" /> -->
            <linkSubNav ref="$subnav" :category-primary-name="item.navigationTitle" :data="item" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="!showMore" class="more-btn-box" @mousemove="moreFn">
      <span class="nav-more" style="margin-left: 32px">更多</span>
      <img src="@/assets/image/right-small-white.svg" alt="icon-arrow-right-small" />
    </div>
  </div>
</template>

<script>
import 'animate.css';
import { GtLink, GtDrawer, pageMixinCommon } from '@speedshop/template';
// import linkSubNav from './link/SubNav.vue';
import { useHome, useAccount, useAdvertisement } from '@speedshop/sdk-api';
import linkSubNav from './link/NewSubNav.vue';
import { queryFilterAdvert } from '@/graphql/nav.gql';

const getClientHeightByCache = () => {
  let clientHeight = 0;
  return () => {
    if (clientHeight) return clientHeight;
    return (clientHeight =
      document?.documentElement?.clientHeight || document?.body?.clientHeight || 0);
  };
};
async function getNavAdvert(code, type) {
  const Advertisement = useAdvertisement();
  Advertisement.queryData = async params => {
    const {
      data: { data }
    } = await Advertisement.gqlClient.query(queryFilterAdvert, params);
    return data;
  };
  const adContent = await Advertisement.getData({
    input: {
      advertCode: code || '',
      deviceType: type
    }
  });
  const {
    AnnouncementBar = [],
    PLPBanner = [],
    PLPBannerWithPosition,
    PageBanner,
    PageBannerWithPosition
  } = adContent;
  const TopAnnouncementBar = AnnouncementBar.length ? AnnouncementBar[0] : {};
  return {
    TopAnnouncementBar,
    AnnouncementBar,
    PLPBanner,
    PLPBannerWithPosition,
    PageBanner,
    PageBannerWithPosition
  };
}

export default {
  name: 'NavBar',
  components: {
    GtLink,
    GtDrawer,
    linkSubNav
  },
  mixins: [pageMixinCommon],
  props: {
    data: { type: Array, default: () => [] },
    defaultMaxWidth: {
      type: Number,
      default: 1380
    }
  },
  data() {
    return {
      subHidden: false,
      lineLeft: 0,
      lineWidth: 0,
      modalHeight: 0,
      getClientHeight: getClientHeightByCache(),
      mobMenuIndex: '',
      activeNavCode: '',
      clickNav: {},
      maxWidth: this.defaultMaxWidth,
      showMore: false,
      navData: [],
      clickDom: null,
      windowObj: null,
      hoverTimer: null
    };
  },
  computed: {
    innerWidth() {
      let result = '100%';
      if (this.windowObj) {
        result = `${this.windowObj.innerWidth}px !important`;
      }
      return result;
    }
  },
  watch: {
    $route: {
      deep: true,
      handler(val, oldVal) {
        if (this.clickDom) {
          this.clickDom.style.pointerEvents = 'none';
          setTimeout(() => {
            this.clickDom.style.pointerEvents = 'auto';
          }, 500);
        }
      }
    },
    data: {
      deep: true,
      immediate: true,
      handler(val, oldVal) {
        console.log(val, '数据执行');
        val?.forEach(child => {
          child.children?.forEach(item2 => {
            console.log('advertList', item2);
            if (item2.advertList && item2.advertList.length) {
              item2.advertList.forEach(async adveritem => {
                adveritem.advertData = await getNavAdvert(
                  adveritem.advertCode,
                  this.isMobile ? 'h5' : 'pc'
                );
              });
            }
          });
        });
        this.navData = JSON.parse(JSON.stringify(this.data)).slice(0, 7);
      }
    }
  },
  mounted() {
    this.windowObj = window;
    this.$nextTick(v => {
      // this.init();
    });
    // window.addEventListener('resize', this.init);
  },
  destroyed() {
    // window.removeEventListener('resize', this.init);
  },
  methods: {
    moreFn() {
      if (this.hoverTimer) return;
      this.hoverTimer = setTimeout(() => {
        this.showMore = true;
        this.navData = this.data.slice(7, this.data.length);
        this.$refs.navBox?.classList.add('nav_animation');
        setTimeout(() => {
          this.$refs.navBox?.classList.remove('nav_animation');
          clearTimeout(this.hoverTimer);
          this.hoverTimer = null;
        }, 1000);
      }, 1000);
    },
    backFn() {
      if (this.hoverTimer) return;
      this.hoverTimer = setTimeout(() => {
        console.log('执行次数');
        this.showMore = false;
        this.navData = this.data.slice(0, 7);
        this.$refs.navBox?.classList.add('nav_animation_back');
        setTimeout(() => {
          this.$refs.navBox?.classList.remove('nav_animation_back');
          clearTimeout(this.hoverTimer);
          this.hoverTimer = null;
        }, 1000);
      }, 1000);
    },
    mouseEnter(e, item) {
      if (!this.isMobile && e) {
        const { top, left, width, height } = e.target.getBoundingClientRect();
        this.modalHeight = this.getClientHeight() - top - height;
        this.lineLeft = left;
        this.lineWidth = width;
      }
      this.activeNavCode = item?.navigationCode || '';
      this.$emit('openNav');
    },
    handleSub(item) {
      this.clickNav = item;
    },
    active(item) {
      if (item.navigationUrl === this.$route.fullPath) {
        this.clickNav = item;
      }
      if (item.children && item.children.length > 0) {
        const secondRes =
          item.children && item.children.map(v => v.navigationUrl).includes(this.$route.fullPath);

        const resList = item.children.map(v => {
          return v.children && v.children.length > 0
            ? v.children.map(c => c.navigationUrl).includes(this.$route.fullPath)
            : false;
        });

        return secondRes || resList.some(v => v != false);
      }
    },

    clickFn(index, item) {
      this.clickNavCode = item.navigationCode;
      if (this.isMobile) {
        if (this.mobMenuIndex === index) {
          this.mobMenuIndex = '';
        } else {
          this.mobMenuIndex = index;
        }
      } else {
        this.mobMenuIndex = index;
      }
    },

    init() {
      this.$nextTick(v => {
        let width = 0;
        if (this.$refs.$nav_item) {
          if (this.$refs.$nav_item.length < 10) {
            this.$refs.$nav_item.forEach(v => {
              width = width + v.offsetWidth + 40;
            });
          } else {
            width = this.defaultMaxWidth;
          }
        }
        if (width > this.defaultMaxWidth) {
          width = this.defaultMaxWidth;
        }
        this.$set(this, 'maxWidth', `${width}px`);
      });
    },
    handleClick(event) {
      this.clickDom = event.currentTarget;
    }
  }
};
</script>

<style lang="scss">
.more-btn-box {
  display: flex;
  cursor: pointer;
  line-height: 59px;
  padding-top: 2px;
  .nav-more {
    font-size: 16px;
  }
  img {
    width: 16px;
  }
}

.ua-nav {
  &.gt-nav {
    position: relative;
    z-index: 10;
    height: 100% !important;
    flex-basis: 100%;
    border-top: var(--border-base);
    border-bottom: var(--border-base);
    max-width: calc(var(--page-max-width) + 100px);
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    min-width: 600px;
    &__sub {
      position: absolute;
      top: 100%;
      left: 50%;
      z-index: 13;
      padding: 20px 36px 20px;
      min-width: calc(100% - 40px);
      width: auto;
      background: #fff;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
      cursor: default;
      visibility: hidden;
      transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s, transform 0.3s;
      -webkit-user-select: none;
      -webkit-appearance: none;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;

      &-wrap {
        transform: translateY(-30px);
        transition: transform 0.3s;
      }
      &-fill {
        max-width: calc(var(--page-max-width) + 100px);
        margin: 0 auto;
        margin-top: var(--header-nav-sub-margin-top);
        left: calc((100% - var(--page-max-width) - 98px) / 2) !important;
        right: 0;
        display: flex;
        max-height: calc(var(--header-nav-fill-max-height) + 60px);
        &.has-image {
          // max-height: 800px !important;
        }
        .gt-nav__sub-label {
          flex-direction: row;
          flex-wrap: wrap;
        }
        .gt-nav__sub-item {
          margin-right: var(--header-nav-sub-margin-right);
          .gt-nav__sub-item-label {
            font-weight: var(--header-nav-fill-sub-font-weight);
          }
        }
        .gt-nav__sub-link {
          display: -webkit-flex;
          display: flex;
          width: fit-content;
          &-wrap {
            max-height: var(--header-nav-fill-max-height);
            &.has-image {
              max-height: auto;
            }
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            .gt-nav__sub-link-item {
              margin-right: var(--header-nav-item-margin-right);
            }
          }
        }
        .gt-nav__sub-wrap {
          width: 100%;
          .gt-nav__sub-container {
            width: 100%;
          }
        }
      }
    }

    .sub-hidden {
      visibility: hidden;
    }

    &__item {
      position: relative;
      height: 100%;
      display: flex;
      justify-content: center;
      &-fill {
        position: static;
      }
      &-label {
        cursor: pointer;
        text-align: center;
        font-size: var(--font-size-large);
        font-weight: 600;
        color: var(--color-text-primary);
        line-height: 102px !important;
        letter-spacing: 1px;
        border-bottom: 2px solid transparent;
        padding: 0 40px;
        span {
          color: var(--main-color-ua-white, #fff);
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px; /* 137.5% */
          text-transform: uppercase;
        }
        &.has-link {
          &:hover {
            transform: translateX(0px);
            color: var(--header-nav-active-color);
            text-decoration: var(--header-nav-active-text-decoration);
            span {
              border-bottom: var(--header-nav-active-underline);
              line-height: var(--header-nav-active-line-height);
            }
          }
        }

        &.is-active {
          transform: translateX(0px);
          color: var(--header-nav-active-color);
          text-decoration: var(--header-nav-active-text-decoration);
          span {
            border-bottom: var(--header-nav-active-underline);
            line-height: var(--header-nav-active-line-height);
          }
        }
      }
      .gt-nav__sub {
        transform: translateX(-50%);
        &-fill {
          transform: translateX(0%);
        }
      }
      &.gt-nav__item__children {
        &.active {
          > div {
            &:hover {
              .gt-nav__sub {
                opacity: 1;
                visibility: visible;
                margin-top: var(--header-nav-sub-margin-top);
                transform: translateX(-50%);
                &-fill {
                  transform: translateX(0%);
                }
                &.sub-hidden {
                  visibility: hidden;
                }
                .gt-nav__sub-wrap {
                  transform: translateY(0);
                }
              }
            }
          }
        }
      }
      .gt-nav__sub.hidden {
        visibility: hidden;
      }
    }

    &__line {
      position: absolute;
      bottom: 1px;
      left: 0;
      width: 0;
      height: 3px;
      z-index: -1;
      background-color: var(--theme-color);
      transition: all 0.3s;
    }
  }
}
</style>

<style lang="scss">
@media screen and (min-width: 800px) and (max-width: 1600px) {
  .ua-nav {
    &.gt-nav {
      position: relative;
      z-index: 10;
      height: 100% !important;
      flex-basis: 100%;
      border-top: var(--border-base);
      border-bottom: var(--border-base);
      max-width: calc(var(--page-max-width) + 40px);
      margin: 0 auto;
      display: flex;
      justify-content: flex-start;
      width: 100%;
      // min-width: 720px;
      &__sub {
        position: absolute;
        top: 100%;
        left: 50%;
        z-index: 13;
        padding: 20px 36px 20px;
        min-width: calc(100% - 40px);
        width: auto;
        background: #fff;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
        cursor: default;
        visibility: hidden;
        transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s, transform 0.3s;
        -webkit-user-select: none;
        -webkit-appearance: none;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;

        &-wrap {
          transform: translateY(-30px);
          transition: transform 0.3s;
        }

        &-fill {
          width: 100%;
          margin: 0 auto;
          left: var(--header-nav-fill-left);
          right: 0;
          display: flex;
        }
      }

      &__item {
        height: 100%;
        display: flex;
        a {
          span {
            word-break: keep-all;
            text-align: left;
            // width: max-content;
            display: inline-block;
            line-height: var(--header-nav-active-line-height);
          }
        }
        &-label {
          cursor: pointer;
          text-align: center;
          font-size: var(--font-size-large);
          font-weight: 600;
          color: var(--color-text-primary);
          line-height: 102px !important;
          letter-spacing: 1px;
          border-bottom: 2px solid transparent;
          &:hover {
            transform: translateX(0px);
            color: var(--header-nav-active-color);
            span {
              border-bottom: var(--header-nav-active-underline);
              line-height: var(--header-nav-active-line-height);
            }
          }
          &.is-active {
            transform: translateX(0px);
            color: var(--header-nav-active-color);
            span {
              border-bottom: var(--header-nav-active-underline);
            }
          }
        }
        &.gt-nav__item__children {
          > div {
            &:hover {
              > .gt-nav__sub {
                opacity: 1;
                margin-top: 1px;
                &-fill {
                  transform: translateX(0%);
                }
                .gt-nav__sub-wrap {
                  transform: translateY(0);
                }
              }
            }
          }
        }

        &:nth-child(1) {
          padding-left: 0;
          &.active {
            &:hover {
              width: max-content;
              .gt-nav__sub {
                left: auto;
                transform: inherit;
                &-fill {
                  transform: translateX(0%);
                  left: var(--header-nav-fill-left);
                }
              }
            }
          }
        }

        &:last-child {
          padding-right: 0;
          &.active {
            &:hover {
              width: max-content;
              .gt-nav__sub {
                right: 0;
                transform: translateX(0%);
                &-fill {
                  transform: translateX(0%);
                }
              }
            }
          }
        }
      }

      .gt-nav__item:first-child {
        padding-left: 0;
        .gt-nav__sub {
          left: 0%;
          transform: translateX(0%);
          &-fill {
            transform: translateX(0%);
            left: var(--header-nav-fill-left);
          }
        }
      }
      &__line {
        position: absolute;
        bottom: 1px;
        left: 0;
        width: 0;
        height: 3px;
        z-index: -1;
        background-color: var(--theme-color);
        transition: all 0.3s;
      }
    }
  }
}
@media (max-width: 1366px) {
  .more-btn-box {
    margin: 0 24px !important;
  }
}
</style>

<style lang="scss">
@media screen and (max-width: 800px) {
  .gt-nav {
    display: none;
  }
}
</style>

<style lang="scss">
.page-layout_mobile {
  .gt-nav {
    display: none;
  }
}
.nav_animation {
  position: relative;
  animation: more 0.5s ease-in-out forwards;
}
.nav_animation_back {
  position: relative;
  animation: back 0.5s ease-in-out forwards;
}

@keyframes back {
  from {
    // opacity: 0;
    transform: translateX(-100%);
  }

  to {
    // opacity: 1;
    transform: translateX(0);
  }
}
@keyframes more {
  from {
    // opacity: 0;
    transform: translateX(100%);
  }

  to {
    // opacity: 1;
    transform: translateX(0);
  }
}
</style>
